// 报价限制
export const quoteLimit = (info)=>{
  const userInfo = JSON.parse(localStorage.getItem("user_info"))
  if(info.user_id == userInfo.id) return '自己的询价单不能进行报价'
  if(info.user.company_id == userInfo.company_id) return '同公司不能进行报价'
  // if(!info.company_id.includes(userInfo.company_id.toString())) return '非指定公司不可报价'
  const bool = [2,3,5].some(item=>userInfo.merchant_type.includes(item.toString()))
  if(!bool) return '非联盟商不能进行报价'
  // if(info.isQuote) return '您已经报过价了'
}

